<template>
    <div 
      v-if="visible"
      :class="{...cssClasses}"
    >
      <fr-label 
        for-id="fr-partnerships"
        class=""
      >
        {{ label || field.meta.label }}
      </fr-label>
      <fr-select
        id="fr-partnerships"
        v-model="fieldValue"
        ref="selectField"
        name="fr-partnerships"
        :valid="!fieldHasErrors('fr-partnerships')"
        :required="field.admin.required"
        :default-option="defaultOption"
        :options="partnershipOptions"
      />
    </div>
  </template>
  
  <script>
  import FrLabel from '@/components/fields/base/FrLabel.vue'
  import FrSelect from '@/components/fields/base/FrSelect.vue'
  import { mapGetters, mapActions } from 'vuex'
  import helpers from '@/helpers/helpers'
  import validation from "@/helpers/validation";
  
  export default {
    name: 'FrPartnerships',
  
    components: {
      FrLabel,
      FrSelect
    },
  
    props: {
      /**
       * The form field that is being rendered.
       */
      field: {
        type: Object,
        required: true
      },
  
      /** 
       * The value that is passed from the parent component through `v-model`.
       */
      modelValue: { 
        type: [Array, Object, String, Number, Boolean],
        default: undefined
      }
    },
  
    emits: ['update:modelValue'],
  
    computed: {
      /**
       * The getters mapped from Vuex.
       */
      ...mapGetters({
        formFields: 'getFormFields',
        submitObject: 'getSubmitObject',
        programsFilter: 'getProgramsFilter',
        schemaObject: 'schemaObject'
  
      }),
  
      /** 
       * The CSS classes to be applied to the element.
       * 
       * This is defined as a computed property so we can dynamically set classes.
       * 
       * @returns {Array}
       */
      cssClasses() {
        return {
          'fr-partnerships': true
        }
      },
      
      /**
       * The value that is passed from the parent component through `v-model`.
       * 
       * This is wrapped as a computed property so that it may be bound 
       * as a `v-model` to a child component. Setting this up as a proxy 
       * bypasses the `Avoid mutating a prop directly` error thrown by Vue.
       * Instead, we intercept this mutation and pass it along to the parent.
       * 
       * @param {Object} val
       * 
       * @returns {Object}
       */
      fieldValue: {
        get() { return this.modelValue },
        set(val) { this.$emit('update:modelValue', val) }
      },
  
      /**
       * The default option to be rendered by the highest degree level dropdown.
       * 
       * @returns {Object}
       */
      defaultOption() {
        return {
          label: this.field.meta.placeholder ?? 'Please select an employer',
          value: '',
          selected: true,
          disabled: true
        }
      },
  
      /**
       * The degree levels to display on the form.
       * 
       * @returns {Array}
       */
      partnershipOptions() {
        let partnerships = []
  
        if (this.field.meta.options.length > 0) {
          partnerships.push(...this.field.meta.options)
        }
  
        partnerships = this.sanitizePartnershipOptions(partnerships)
        return [
          ...partnerships 
        ]
      },
  
      /**
       * For dynamic display of fields
       * @returns {Boolean}
       */
      visible() {
        // if there is no visibility property, show the field
        // if the visibility array is empty or not set, show the field
        // if the visibilty array has an object in it, and the toggle is show, and the match condition is and, only return true if all conditions match
        // if the visibilty array has an object in it, and the toggle is show, and the match condition is or, only return false if no conditions match
        // if the visibilty array has an object in it, and the toggle is hide, and the match condition is and, only return false if all conditions match 
        if(!this.field.meta.visibility) {
          return true
        }
  
        if(this.field.meta.visibility.length === 0) {
          return true
        }
        if(this.field.meta.visibility.length > 0 && this.field.meta.visibility[0].toggle === "show") {
          return helpers.conditionalLogicEvaluation('visibility', this.formFields, this.field.meta, this.submitObject, this.programsFilter)        
        }
        if(this.field.meta.visibility.length > 0 && this.field.meta.visibility[0].toggle === "hide") {
          return !(helpers.conditionalLogicEvaluation('visibility', this.formFields, this.field.meta, this.submitObject, this.programsFilter))        
   
        }
        //just return true as a default emergency escape case
        return true
      },
      
      /**
       * For dynamic label settings
       * @returns {String}
       */
      label() {
        //if no attribute, return null for the default form config val to display
         if(!this.field.meta.display) {
          return null
        }
  
        if(this.field.meta.display.length === 0) {
          return null
        }
        //if length, if display name is not null or an empty string, else default
        if(this.field.meta.display.length > 0 && this.field.meta.display[0].displayName && this.field.meta.display[0].displayName !== '')  {
          //if conditional logic evaluates to true
          if(helpers.conditionalLogicEvaluation('display', this.formFields, this.field.meta, this.submitObject, this.programsFilter)) {
            return this.field.meta.display[0].displayName
          }
          return null      
        }
  
        return null
  
      }     
    },
  
    watch: {
      /**
       * Sets the validation to ignore if the field is not visible
       * @param {*} newVal 
       */
      visible(newVal) { 
        let steps = validation.setIgnoreValidation(this.field.id, !newVal)
        const { schema, labelLut } = validation.generateStepsSchema(steps)
        this.setSchemaObjectValue({key: "schema", value: schema})
        if(!newVal){
          this.fieldValue = this.field.meta.default ?? ''
        }
      }
    },
  
  
    created() {
      let steps = validation.setIgnoreValidation(this.field.id, !this.visible)
      const { schema, labelLut } = validation.generateStepsSchema(steps)
      this.setSchemaObjectValue({key: "schema", value: schema})  
    },
  
    methods: {
      ...mapActions(["setSchemaObjectValue"]),
      
      /**
       * Sanitizes the degree level options to include the `selected` and `disabled` keys.
       * 
       * @param {Array}
       */
       sanitizePartnershipOptions(options) {
        let sanitizedOptions = []
        let optionMap = {
          selected: false,
          disabled: false
        }
  
        options.forEach(option => {
          for (let [key, value] of Object.entries(optionMap)) {
            if (! Object.prototype.hasOwnProperty.call(option, key)) {
              option[key] = value
            }
          }
  
          sanitizedOptions.push(option)
        })
  
        return sanitizedOptions
      },
  
      fieldHasErrors(name) {
        return validation.fieldHasErrors(name)
      },
  
      focus() {
        this.$refs.selectField.focus();
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  // Styles go hurr.
  </style>
  