import validation from "../../helpers/validation";
import state from '@/store/state.js'

//add asynchronous call to twilio client on leads pipeline
function validEducationLevel(val) {
    let program = state.formConfig.programs.filter(program => program.id === state.submit.program)[0];
    let qualifierOption = program.programQualifier.data.options.filter(option => option.label == val)[0];
    
    if(qualifierOption && qualifierOption.is_acceptable_level) {
        return true
    } else {
        validation.validationMessage['program-qualifier-dropdown'] = program?.programQualifier?.data?.validation_message ? program.programQualifier.data.validation_message : "You do not meet the minimum education requirements";
        return false
    }
 
} //end func

export default validEducationLevel
