<template>
  <div 
    v-if="visible"
    :class="{...cssClasses}"
  >
    <fr-label
      for-id="fr-phone"
      class=""
    >
      {{ label || field.meta.label }}
    </fr-label>
    <fr-input
      id="fr-phone"
      v-model="fieldValue"
      ref="inputField"
      class=""
      name="fr-phone"
      type="tel"
      :valid="!fieldHasErrors('fr-phone')"
      :required="field.admin.required"
      autocomplete="null"
      maxlength="15"
      @blur="handleBlur"
    />
  </div>
</template>

<script>
import FrLabel from "@/components/fields/base/FrLabel.vue";
import FrInput from "@/components/fields/base/FrInput.vue";
import helpers from "@/helpers/helpers";
import { mapGetters, mapActions } from "vuex";
import intlTelInput from "intl-tel-input";
import * as Utils from "intl-tel-input/build/js/utils";
import "intl-tel-input/build/css/intlTelInput.css";
import axios from "axios";
import validation from '@/helpers/validation'

export default {
  name: "FrPhone",

  components: {
    FrInput,
    FrLabel
  },

  props: {
    /**
     * The form field that is being rendered.
     */
    field: {
      type: Object,
      required: true,
    },

    /**
     * The value that is passed from the parent component through `v-model`.
     */
    modelValue: {
      type: [Array, Object, String, Number, Boolean],
      default: undefined
    }
  },

  emits: ['update:modelValue'],

  data() {
    return {
      iti: {},
      input: {},
    };
  },

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      formFields: "getFormFields",
      submitObject: "getSubmitObject",
      programsFilter: "getProgramsFilter",
      formVariant: "getFormVariant",
      lastPhone: "getLastPhone",
    }),

    /**
     * The CSS classes to be applied to the element.
     *
     * This is defined as a computed property so we can dynamically set classes.
     *
     * @returns {Array}
     */
    cssClasses() {
      return {
        "fr-phone": true,
      };
    },

    /**
     * The value that is passed from the parent component through `v-model`.
     *
     * This is wrapped as a computed property so that it may be bound
     * as a `v-model` to a child component. Setting this up as a proxy
     * bypasses the `Avoid mutating a prop directly` error thrown by Vue.
     * Instead, we intercept this mutation and pass it along to the parent.
     *
     * @param {String} val
     *
     * @returns {String}
     */
    fieldValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },

    /**
     * For dynamic display of fields
     * @returns {Boolean}
     */
    visible() {
      // if there is no visibility property, show the field
      // if the visibility array is empty or not set, show the field
      // if the visibilty array has an object in it, and the toggle is show, and the match condition is and, only return true if all conditions match
      // if the visibilty array has an object in it, and the toggle is show, and the match condition is or, only return false if no conditions match
      // if the visibilty array has an object in it, and the toggle is hide, and the match condition is and, only return false if all conditions match
      if (!this.field.meta.visibility) {
        return true;
      }

      if (this.field.meta.visibility.length === 0) {
        return true;
      }
      if (
        this.field.meta.visibility.length > 0 &&
        this.field.meta.visibility[0].toggle === "show"
      ) {
        return helpers.conditionalLogicEvaluation(
          "visibility",
          this.formFields,
          this.field.meta,
          this.submitObject,
          this.programsFilter
        );
      }
      if (
        this.field.meta.visibility.length > 0 &&
        this.field.meta.visibility[0].toggle === "hide"
      ) {
        return !helpers.conditionalLogicEvaluation(
          "visibility",
          this.formFields,
          this.field.meta,
          this.submitObject,
          this.programsFilter
        );
      }
      //just return true as a default emergency escape case
      return true;
    },

    /**
     * For dynamic label settings
     * @returns {String}
     */
    label() {
      //if no attribute, return null for the default form config val to display
      if (!this.field.meta.display) {
        return null;
      }

      if (this.field.meta.display.length === 0) {
        return null;
      }
      //if length, if display name is not null or an empty string, else default
      if (
        this.field.meta.display.length > 0 &&
        this.field.meta.display[0].displayName &&
        this.field.meta.display[0].displayName !== ""
      ) {
        //if conditional logic evaluates to true
        if (
          helpers.conditionalLogicEvaluation(
            "display",
            this.formFields,
            this.field.meta,
            this.submitObject,
            this.programsFilter
          )
        ) {
          return this.field.meta.display[0].displayName;
        }
        return null;
      }

      return null;
    },
  },

  watch: {
    visible: {
      handler: function (val) {
      let steps = validation.setIgnoreValidation(this.field.id, !val)
      const { schema, labelLut } = validation.generateStepsSchema(steps)
      this.setSchemaObjectValue({key: "schema", value: schema})

        if (val) {
          this.$nextTick(function () {
            this.initializeTelInput(document.querySelector("#fr-phone"));
          });
        }
        if(!val){
          this.fieldValue = this.field.meta.default ?? ''
        }
      },
    },
  },

  mounted() {
    if(this.visible){
      this.initializeTelInput(document.querySelector("#fr-phone"));
    }
  },


  created() {
    let steps = validation.setIgnoreValidation(this.field.id, !this.visible)
    const { schema, labelLut } = validation.generateStepsSchema(steps)
    this.setSchemaObjectValue({key: "schema", value: schema})  
  },

  methods: {
    /**
     * The actions mapped from Vuex.
     */
     ...mapActions({
      setLastPhone: 'setLastPhone',
      setSchemaObjectValue: 'setSchemaObjectValue'
    }),
    
    /**
     * The blur-only interaction mode
     */
    // async handleBlur(validation) {
    async handleBlur() {
      if(this.lastPhone != this.fieldValue) {
        this.setLastPhone(this.fieldValue)
      } 
    },

    async initializeTelInput(input) {
      let initialCountry = await this.getUserCountry()
      this.input = input;
      this.iti = intlTelInput(this.input, {
        nationalMode: false,
        autoInsertDialCode: true,
        autoPlaceholder: "off",
        initialCountry: initialCountry,
        utilsScript: Utils,
      });

      //// Trigger Prevalidations with the keyup event
      document
        .querySelector("[name=fr-phone]")
        .addEventListener("keyup", () => {
          this.cus_prevalidate();
        });

      //// Trigger number formatting on blur
      document.querySelector("[name=fr-phone]").addEventListener("blur", () => {
        var c_code = "";
        var formattedPhoneNumber = this.iti.getNumber(
          window.intlTelInputUtils.formatNumber
        );

        if (this.iti.selectedCountryData["dialCode"]) {
          c_code = "+" + this.iti.selectedCountryData["dialCode"];

          formattedPhoneNumber = formattedPhoneNumber.replace(c_code + " ", "");
          formattedPhoneNumber = formattedPhoneNumber.replace(c_code, "");
          formattedPhoneNumber = formattedPhoneNumber.replaceAll("+", "");

          c_code = c_code + " ";
        }

        this.input.value = c_code + formattedPhoneNumber;

        this.native_evnt_dispatch();

        this.cus_prevalidate(); // Run prevalidations again for blur event
      });

      // Function when the flag changes
      this.input.addEventListener("countrychange", () => {
        this.native_evnt_dispatch();
      });
    },

    async getUserCountry() {
      try {
        let response = await axios.get("https://in-us.wiley.tools/");
        //if there is a response, and it has a length of 2, use it
        if(response.data && response.data.length === 2) {
          return response.data
        }
        //return us by default
        return "US";
      } catch (err) {
        console.error(err);
      }
    },

    // dispatch native event
    native_evnt_dispatch() {
      // Create native event
      const event = new Event("input", { bubbles: true });

      // Dispatch the event on "native" element
      this.input.dispatchEvent(event);
    },

    // Prevalidations
    cus_prevalidate() {
      var c_code = "";

      if (this.iti.selectedCountryData["dialCode"]) {
        c_code = this.iti.selectedCountryData["dialCode"];
      }

      var curr_dcode = "+" + c_code;

      if (this.input.value == "") {
        // If empty, add the dialcode automatically
        this.input.value = curr_dcode;
        this.native_evnt_dispatch();
      }
    },

    fieldHasErrors(name) {
      return validation.fieldHasErrors(name)
    },
    focus() {
      this.$refs.inputField.focus();
    }
  },
};
</script>

<style lang="scss">
#fr-phone {
  padding-left: 50px !important;
}

.fr-phone {
  width: 100%;
}

.iti__country-list {
  width: 100%;
  white-space: normal;
  color: #000;
  font-size: 13px;
  z-index: 3;
}

.iti__selected-flag {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
}

.iti__selected-dial-code {
  color: #000;
  font-size: 14px;
}

.iti {
  width: 100%;
  transition: box-shadow ease 0.25s;

  &:focus {
    outline: none;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  }
}

.iti__flag-container {
  position: static;
  padding-top: 0px;
  padding-bottom: 0px;
  width: 50px;
}
</style>
