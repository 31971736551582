export default {
  /**
   * Sets a cookie on the browser instance for the specified amount of days.
   * 
   * Cookie format: {key}={value}; {expires}={date}; {path}={/}
   * 
   * @param {String} key
   * @param {String} value
   * @param {Number} ttl
   * 
   * @returns {String}
   */
  setCookie(key, value, ttl = 1) {
    let expires = ''
    let path = '; path=/'

    if (ttl) {
      let date = new Date()
      date.setTime(date.getTime() + (ttl * 24 * 60 * 60 * 1000))
      expires = `; expires=${date.toGMTString()}`
    }

    document.cookie = `${key}=${value}${expires}${path}`

    return value
  },

  /**
   * Retrieve a cookie with the specified name.
   * 
   * @param {String} key
   * 
   * @returns {String|Null}
   */
  getCookie(name = '') {
    let cookieArray = document.cookie.split(`;`);
    
    for(let i = 0; i < cookieArray.length; i++) {
      if (cookieArray[i].trim().startsWith(`${name}=`)) {
        let cookie

        try {
          cookie = cookieArray[i].split(`${name}=`)[1]
        } catch (e) {
          console.warn(`Freya Form Renderer: There was a problem retrieving the cookie '${name}'.`)

          return null
        }

        return cookie
      }
    }

    return null;
  },

  /**
   * Extract the query string parameters into an object.
   * 
   * @param {String} queryString
   * 
   * @return {Object}
   */
  parseQueryParameters(queryString) {
    let lookup = {}
    let parameterArray = queryString.split('&')

    parameterArray.forEach(pair => {
      let pairArray = pair.split(/=(.+)/)
      
      if (pairArray.length > 1) {
        lookup[pairArray[0]] = decodeURIComponent(
          pairArray[1].replace(/\+/g, ' ')
        )
      }
    })

    return lookup
  },

  /**
   * Redirects the user after the form has been submitted.
   * 
   * TODO: This is kind of sloppy, but it works for now.
   * At some point it would be good to find a better way to handle this.
   * 
   * @param {String}  redirect
   * @param {Object}  data
   * 
   * @return {Void}
   */
  redirectAfterSubmission(redirect, data) {
    let queryString = []

    if (Object.prototype.hasOwnProperty.call(data, 'isLandingPage')) {
      queryString.push(`lp=${data.isLandingPage}`)
    }

    if (Object.prototype.hasOwnProperty.call(data, 'orderId')) {
      queryString.push(`orderid=${data.orderId}`)
    }

    if (Object.prototype.hasOwnProperty.call(data, 'program')) {
      queryString.push(`uuid=${data.program}`)
    }

    if (Object.prototype.hasOwnProperty.call(data, 'programCode')) {
      queryString.push(`d=${data.programCode}`)
    }
		
    if (Object.prototype.hasOwnProperty.call(data, 'entryId')) {
      queryString.push(`entry_id=${data.entryId}`)
    }

    if (Object.prototype.hasOwnProperty.call(data, 'pweid')) {
      queryString.push(`pweid=${data.pweid}`)
    }

    if (redirect.startsWith('http') || redirect.startsWith('/')) {
      window.location = `${redirect}/?${queryString.join('&')}`
    } else {
      window.location = `/${redirect}/?${queryString.join('&')}`
    }
  },

  /**
   * Generates an order ID.
   * 
   * @returns {String}
   */
  generateOrderId() {
    return (Date.now() + Math.floor(1000 + Math.random() * 9000)).toString()
  },
	
	generateEntryId() {
		return crypto.getRandomValues(new Uint32Array(4)).join('').toString(36)
	},

	generatePartnerWebsiteExternalId() {
		return (Math.floor(1000 + Math.random() * 9000) + '-' + window.location.hostname + '-' + Date.now()).toString();
	},

  /**
   * Google Analytics 4 Client ID 
   */
  getMeasurementId() {
    for (let i = 0; i < window.dataLayer.length; i++) {
        if (window.dataLayer[i][0] === 'config') {
            return window.dataLayer[i][1];
        }
     }
    return null;
  },
 
  getClientId() {
    //get the client id google analytics cookie _ga
    //if it doesn't exist, return null
    if(!!this.getCookie("_ga")) {
      return this.getCookie("_ga").substring(6) //the substring pulls the client id
    }

    return null
  },


  createCookie(cname, cvalue, exdays) {
    var d = new Date()
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
    var expires = "expires="+d.toUTCString()
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
  },
  
  readCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';')
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
        }
    }
    return "";
  },
  
  equality(field, value, operator) {
    if(operator === "equal") {
      return field === value
    }
    if(operator === "not equal") {
      return field !== value
    }
  },

  visibilityCheck(matchCondition, array) {
    if(matchCondition === "and") {
      return array.reduce((accumulated, current) => accumulated && current)
    }
    if(matchCondition === "or") {
      return array.reduce((accumulated, current) => accumulated || current)

    }

  },

  conditionalLogicEvaluation(conditionalLogicType, formFields, fieldMeta, submitObj, progFilter) {
    let condition = {}
        for(let i = 0; i < fieldMeta[conditionalLogicType].length; i++) {
          let fieldVal = ''
          let field = formFields.find(field => field.id === fieldMeta[conditionalLogicType][i].field)
          if(field.id === 'programs-filter') {
            fieldVal = progFilter.value
          }
          else {
            fieldVal = submitObj[field.meta.submitKey]
          }
          condition[i] = this.equality(fieldVal, fieldMeta[conditionalLogicType][i].values[0], fieldMeta[conditionalLogicType][i].operator)
        }         
        if(fieldMeta[conditionalLogicType][0].matchCondition === "and") {
          return this.visibilityCheck("and", Object.values(condition))
        }
        if(fieldMeta[conditionalLogicType][0].matchCondition === "or") {          
          
          return this.visibilityCheck("or", Object.values(condition))
        }  
  },

  parseErrorMessages(err){
    return err.inner.reduce((obj, e) => {
      if (!(e.path in obj)) obj[e.path] = []
      obj[e.path] = obj[e.path].concat(e.errors)
      return obj
    }, {})
  },

  normalizeFilterValue(degree) {
    return degree
        .toLowerCase()               // convert to lowercase
        .replace(/['']s\b/g, '')    // remove 's at the end of words
        .replace(/s\b/g, '')        // remove plural s at the end
        .trim();                    // remove any extra spaces
  }

}