<template>
  <!-- Honeypot field (invisible to users and screen readers) -->
  <div class="hp-only" aria-hidden="true">
    <label for="honeypot" class="hp-only">Honeypot</label>
    <input v-model="honeypot" type="text" id="honeypot" name="honeypot" />
  </div>

  <span id="formErrorMessages" class="sr-only" aria-live="assertive"></span>
  <div class="fr-form-controls">
    <fr-button v-if="formStepsCount > 1 && currentFormStep > 1" :classes="['fr-button--previous']"
      @click.prevent="handlePreviousButton">
      <span>{{ formControls.previous.text }}</span>
    </fr-button>

    <fr-button v-if="formStepsCount > 1 && currentFormStep < formStepsCount" :classes="['fr-button--next']"
      @click.prevent="handleNextButton">
      <span>{{ formControls.next.text }}</span>
    </fr-button>

    <fr-button v-if="formStepsCount === currentFormStep" :classes="['fr-button--submit']" :disabled="isSubmitting"
      @click="handleSubmitButton">
      <span v-if="!isSubmitting">{{ formControls.submit.text }}</span>
      <svg v-else width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" />
        <path
          d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z">
          <animateTransform attributeName="transform" type="rotate" dur="0.75s" values="0 12 12;360 12 12"
            repeatCount="indefinite" />
        </path>
      </svg>
    </fr-button>
  </div>
</template>

<script>
import FrButton from "@/components/fields/base/FrButton.vue";
import { mapActions, mapGetters } from "vuex";
import helpers from "@/helpers/helpers"

export default {
  name: "FrFormControls",

  components: {
    FrButton,
  },

  emits: ['submission-attempted'],

  data() {
    return {
      isButtonClicked: false,
      honeypot: '',
    };
  },

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      formVariant: "getFormVariant",
      currentFormStep: "getCurrentFormStep",
      formStepsCount: "getFormStepsCount",
      isSubmitting: "getIsSubmitting",
      schemaObject: "getSchemaObject",
      submitObject: "getSubmitObject",
      programsFilter: "getProgramsFilter"
    }),

    /**
     * The form controls section of the form config.
     *
     * @returns {Object}
     */
    formControls() {
      return this.formVariant.controls;
    },
  },

  watch: {
    isSubmitting(value) {
      if (!value) {
        this.isButtonClicked = false;
      }
    },
  },

  methods: {
    /**
     * The actions mapped from Vuex.
     */
    ...mapActions(["decrementFormStep", "incrementFormStep", "submitForm"]),

    /**
     * Handles what happens when the submit button is clicked.
     *
     * @returns {Void}
     */
    async handleSubmitButton() {
      if (this.isButtonClicked || this.isSubmitting) return;
      // If the honeypot field is filled out, it's a bot
      if (this.honeypot) return;
    
      this.$emit('submission-attempted');

      this.isButtonClicked = true;
      try {
        await this.schemaObject.schema[this.currentFormStep - 1]
        .validate({ ...this.submitObject, programsFilter: this.programsFilter.value}, { abortEarly: false, stripUnknown: false })
        this.schemaObject.errors = {};
        this.submitForm();
      } catch (err) {
        this.isButtonClicked = false
        this.schemaObject.errors = helpers.parseErrorMessages(err)
        const errorContainer = document.getElementById('formErrorMessages');
        const errorMessage = Object.values(this.schemaObject.errors)[0];
        errorContainer.textContent = ''; // Clear existing message
        errorContainer.textContent = `Form submission failed. There are errors that need your attention. ${errorMessage}`; 

        // Focus on the first field with an error
        const firstErrorField = this.findKeyByValue(this.schemaObject.lut, Object.keys(this.schemaObject.errors)[0])
        const firstErrorElement = document.querySelector(`[name="${firstErrorField}"]`);
        if (firstErrorElement) {
          firstErrorElement.focus();
        }
      }
    },

    /**
     * Handles what happens when the next button is clicked.
     *
     * @returns {Void}
     */
    async handleNextButton() {
      this.schemaObject.schema[this.currentFormStep - 1]
        .validate({ ...this.submitObject, programsFilter: this.programsFilter.value}, { abortEarly: false, stripUnknown: false })
        .then(value => {
          this.schemaObject.errors = {}
          this.incrementFormStep()
        })
        .catch(err => {
          this.schemaObject.errors = helpers.parseErrorMessages(err)
        })
    },

    /**
     * Handles what happens when the previous button is clicked.
     *
     * @returns {Void}
     */
    handlePreviousButton() {
      this.decrementFormStep();
    },

    findKeyByValue(object, valueToFind) {
      return Object.keys(object).find(key => object[key] === valueToFind);
    }
  }
};
</script>

<style lang="scss" scoped>
.fr-form-controls {
  display: flex;
  justify-content: space-between;
}

svg path {
  fill: currentColor;
}

.fr-button--submit {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.hp-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: 0;
  border: 0;
  opacity: 0;
  visibility: hidden;
}
</style>