<template>
  <div
  v-if="hasPhoneNumber && ctaPhoneConfig.isVisible"
  :class="{...cssClasses}"
  >
    <fr-label>
      <span class="mobile-display">{{ ctaHtmlNoPhone }}<a :href="'tel:' + phoneNumber">{{ phoneNumber }}</a></span>
        <span class="desktop-display">{{ ctaHtml }}</span>
    </fr-label> 
  </div>

</template>

<script>
import FrLabel from '@/components/fields/base/FrLabel.vue'
import { mapActions, mapGetters } from "vuex";


export default {
  name: "FrPhoneCta",

  components: {
    FrLabel
  },


  data() {
    return {
    };
  },

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      formVariant: "getFormVariant",
      formConfig: "getFormConfig",
      programs: "getAvailablePrograms",
      selectedProgram: "getSelectedProgram"
    }),
    
    /** 
     * The CSS classes to be applied to the element.
     * 
     * This is defined as a computed property so we can dynamically set classes.
     * 
     * @returns {Array}
     */
     cssClasses() {
      return {
        'fr-phone-cta': true
      }
    },   

    ctaPhoneConfig() {
        return this.formVariant.ctaPhone
    },

    phoneNumber() {
      // load the first program attached to the form, else null
      if(this.programs.length > 0) {
        return this.programs[0].account.data.cta_phone ?? null
      }
      return this.formConfig.ctaPhone
    },

    ctaHtml() {
        
        //pull the [PARTNER_PHONE] value and replace it with the phone number value
        let ctaField = this.ctaPhoneConfig
        let ctaText = ctaField.label
 
        ctaText = ctaText.replace(/\[PARTNER_PHONE\]/g, this.phoneNumber)

        return ctaText


    },

    ctaHtmlNoPhone() {
        
        //pull the [PARTNER_PHONE] value and replace it with an empty string to replace with phone link
        let ctaField = this.ctaPhoneConfig
        let ctaText = ctaField.label
 
        ctaText = ctaText.replace(/\[PARTNER_PHONE\]/g, '')

        return ctaText


    },    

    hasPhoneNumber() {
        //if the cta phone json exists and the program's account data has a phone number attached
        if(!!this.ctaPhoneConfig && !!this.phoneNumber) {
            return true
        }
        return false
    }
  },

  watch: {

  },

  methods: {
    
  }
};
</script>

<style lang="scss" scoped>
.fr-phone-cta { 
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0.5rem;
}

.mobile-display {
    display: none;
}

@media (max-width: 600px){ 
    .desktop-display {
        display: none;
    }
    .mobile-display {
        display: inline;
    }
}

</style>