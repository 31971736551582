<template>
  <div class="fr-privacy-text">
    <fr-label>
      <span v-html="privacyHtml" />
    </fr-label>
  </div>
</template>
<script>
import FrLabel from '@/components/fields/base/FrLabel.vue'
import defaultPrivacyText from '@/data/defaults/privacyText.js'
import { mapGetters } from 'vuex'
export default {
  name: 'FrPrivacyText',

  components: {
    FrLabel,
  },

  computed: {
    /** 
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      formConfig: 'getFormConfig',
      formVariant: 'getFormVariant',
      partnerName: 'getPartnerName',
    }),

    /**
     * The SMS consent html to render on the form.
     * 
     * @returns {String}
     */
    privacyHtml() {
      let privacy = this.formVariant.privacy || defaultPrivacyText //load the privacy text object
      let privacyLabel = this.formVariant.privacy.label || privacy.label

      if (privacy.links) {
        privacyLabel = privacyLabel.replace(/\[PARTNER_NAME\]/g, this.partnerName)

        privacy.links.forEach((link) => {
          privacyLabel = privacyLabel.replace(`[${link.tag}]`, `<a target="_blank" href="${link.url}">${link.text}</a>`)
        })
      } else {
        privacyLabel = privacyLabel.replace(/\[LINK\]/g, `<a target="_blank" href="${privacy.url}">${privacy.text}</a>`)
      }

      return privacyLabel
    },

  },

}
</script>

<style lang="scss" scoped>
.fr-privacy-text {
  display: flex;
  margin-top: 0.5em;

  label {
    font-size: 0.625rem;
    text-transform: none;
    font-weight: normal;
  }
}
</style>