import state from '@/store/state.js'
import getters from '@/store/getters.js'
import mutations from '@/store/mutations.js'
import actions from '@/store/actions.js'

/**
 * This simply creates the scaffolding for a Vuex module.
 * A Vuex store must be created elsewhere and receive this as an injected module.
 */
export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}