<template>
  <div 
    v-if="visible"
    :class="{...cssClasses}"
  >
    <fr-label 
      for-id="fr-program-qualifier-dropdown"
      class=""
    >
      {{ qualifier.label }}
    </fr-label>
    <fr-select
      id="fr-program-qualifier-dropdown"
      v-model="fieldValue"
      ref="selectField"
      :valid="!fieldHasErrors('fr-program-qualifier-dropdown')"
      :required="field.admin.required"
      class=""
      name="fr-program-qualifier-dropdown"
      :options="programQualifierOptions"
      :default-option="defaultOption"
    />
  </div>
</template>

<script>
import FrLabel from '@/components/fields/base/FrLabel.vue'
import FrSelect from '@/components/fields/base/FrSelect.vue'
import { mapGetters, mapActions } from 'vuex'
import helpers from '@/helpers/helpers'
import validation from "@/helpers/validation";

export default {
  name: 'FrProgramQualifierDropdown',

  components: {
    FrLabel,
    FrSelect
  },

  props: {
    /**
     * The form field that is being rendered.
     */
    field: {
      type: Object,
      required: true
    },

    /** 
     * The value that is passed from the parent component through `v-model`.
     */
    modelValue: {
      type: [Array, Object, String, Number, Boolean],
      default: undefined
    }
  },

  emits: ['update:modelValue'],

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      programs: 'getPrograms',
      selectedProgramId: 'getSelectedProgramId',
      formFields: 'getFormFields',
      submitObject: 'getSubmitObject',
      programsFilter: 'getProgramsFilter',
      schemaOverrides: 'getSchemaOverrides' ,
      schemaObject: 'schemaObject'     
    }),

    /** 
     * The CSS classes to be applied to the element.
     * 
     * This is defined as a computed property so we can dynamically set classes.
     * 
     * @returns {Array}
     */
    cssClasses() {
      return {
        'fr-program-qualifier-dropdown': true
      }
    },
    
    /**
     * The value that is passed from the parent component through `v-model`.
     * 
     * This is wrapped as a computed property so that it may be bound 
     * as a `v-model` to a child component. Setting this up as a proxy 
     * bypasses the `Avoid mutating a prop directly` error thrown by Vue.
     * Instead, we intercept this mutation and pass it along to the parent.
     * 
     * @param {Object} val
     * 
     * @returns {Object}
     */
    fieldValue: {
      get() { return this.modelValue },
      set(val) { this.$emit('update:modelValue', val) }
    },

    defaultOption() {
      return {
        label: this.field.meta.placeholder ?? 'Please select an option',
        value: false,
        selected: true,
        disabled: true
      }
    },

    /**
     * The default options to be rendered by the qualifier.
     * 
     * @returns {Array}
     */
    defaultOptions() {
      return [
        {
          label: 'Yes',
          value: true,
          selected: false,
          disabled: false
        },
        {
          label: 'No',
          value: false,
          selected: false,
          disabled: false
        }
      ]
    },

    /**
     * The program qualifier options to display on the form.
     * 
     * @returns {Array}
     */
    programQualifierOptions() {
      if (
        this.qualifier && 
        Object.prototype.hasOwnProperty.call(this.qualifier, 'options') && 
        this.qualifier.options.length > 0
      ) {
        return this.qualifier.options
      } else {
        return this.defaultOptions
      }
    },

    /**
     * The program object that is currently selected.
     * 
     * TODO: Potentially extract this to a getter on the store.
     * This would abstract the data and logic away from this component
     * in case the functionality is needed again elsewhere in the codebase.
     * 
     * @returns {Object}
     */
    selectedProgram() {
      return this.programs.find(program => program.id === this.selectedProgramId)
    },

    /**
     * The qualifier object for the currently selected program.
     * 
     * @returns {Object|null}
     */
    qualifier() {
      // All of these checks need to be in place for sanity.
      // I'll come back and refactor this at some point to make it prettier.
      if (
        ! this.selectedProgram || // There is no selected program.
        ! Object.prototype.hasOwnProperty.call(this.selectedProgram, 'programQualifier') || // The selected program doesn't have the qualifier key.
        ! Object.prototype.hasOwnProperty.call(this.selectedProgram.programQualifier, 'data') || // The selected program doesn't have the data key.
        Object.keys(this.selectedProgram.programQualifier.data).length === 0 && // The selected program doesn't have a qualifier.
        (this.selectedProgram.programQualifier.data.type !== 'dropdown' || // The selected program isn't of the 'dropdown' type.
        this.selectedProgram.programQualifier.data.type !== 'education level')
      ) {
        return null
      }

      return this.selectedProgram.programQualifier.data
    },

    /**
     * For dynamic display of fields
     * @returns {Boolean}
     */
    visible() {
      return !!this.qualifier
    },
    
    /**
     * For dynamic label settings
     * @returns {String}
     */
    label() {
      //if no attribute, return null for the default form config val to display
       if(!this.field.meta.display) {
        return null
      }

      if(this.field.meta.display.length === 0) {
        return null
      }
      //if length, if display name is not null or an empty string, else default
      if(this.field.meta.display.length > 0 && this.field.meta.display[0].displayName && this.field.meta.display[0].displayName !== '')  {
        //if conditional logic evaluates to true
        if(helpers.conditionalLogicEvaluation('display', this.formFields, this.field.meta, this.submitObject, this.programsFilter)) {
          return this.field.meta.display[0].displayName
        }
        return null      
      }

      return null

    }   
  },

  watch: {
    /**
     * Sets the validation to ignore if the field is not visible
     * @param {*} newVal 
     */
    visible(newVal) { 
      let steps = validation.setIgnoreValidation(this.field.id, !newVal)
      const { schema, labelLut } = validation.generateStepsSchema(steps)
      this.setSchemaObjectValue({key: "schema", value: schema})
      if(!newVal){
        this.fieldValue = this.field.meta.default ?? ''
      }
    }
  },

  mounted() {
    this.setSchemaOverrides()
  },

  updated() {
    this.setSchemaOverrides()
  },


  created() {
    let steps = validation.setIgnoreValidation(this.field.id, !this.visible)
    const { schema, labelLut } = validation.generateStepsSchema(steps)
    this.setSchemaObjectValue({key: "schema", value: schema})  
  },

  methods: {
    ...mapActions(["setSchemaObjectValue"]),
        
    setSchemaOverrides(){
      // set schema overrides
      // Remove the 'required' rule if we want to handle program substitution.
      if (!!this.qualifier && 'substitution' in this.qualifier && this.qualifier.substitution) {
        if(!this.schemaOverrides.fields['fr-programs']) { this.schemaOverrides.fields['fr-programs'] = {} }
        this.schemaOverrides.fields['fr-programs'].required = false
      }
    },

    fieldHasErrors(name) {
      return validation.fieldHasErrors(name)
    },

    focus() {
      this.$refs.selectField.focus();
    }
  },
}
</script>

<style lang="scss" scoped>
// Styles go hurr.
</style>
