<template>
  <select
    :id="id"
    v-model="fieldValue"
    ref="selectField"
    :class="{ ...cssClasses, ...validationClasses(name) }"
    :name="name"
    :aria-invalid="isInvalid"
    :aria-required="required" 
    :aria-describedby="schemaHasErrors() ? `${id}-error` : null"
    @blur="onBlur"
    @change="onChange"
  >
    <!-- Default Option -->
    <fr-select-option 
      v-if="defaultOption"
      :value="defaultOption.value"
      :selected="defaultOption.selected" 
      :disabled="defaultOption.disabled"
    >
      {{ defaultOption.label }}
    </fr-select-option>

    <template v-for="(option, optionIndex) in options">
      <!-- If the object has a `value` key, it is a single option. -->
      <template v-if="Object.prototype.hasOwnProperty.call(option, 'value')">
        <fr-select-option
          :key="optionIndex"
          :value="option.value"
          :selected="option.selected"
          :disabled="option.disabled"
        >
          {{ option.label }}
        </fr-select-option>
      </template>

      <!-- If the object has no `items` key, and is using `label` as the value. -->
      <template v-if="!Object.prototype.hasOwnProperty.call(option, 'value') && !Object.prototype.hasOwnProperty.call(option, 'items')">
        <fr-select-option
          :key="optionIndex"
          :value="option.label"
          :selected="option.selected"
          :disabled="option.disabled"
        >
          {{ option.label }}
        </fr-select-option>
      </template>

      <!-- If the object has an `items` key, and no `label`, it is a regular select option. -->
      <template v-if="Object.prototype.hasOwnProperty.call(option, 'items') && option.label === null">
        <fr-select-option
          v-for="(item, itemIndex) in option.items"
          :key="itemIndex"
          :value="item.value"
          :selected="item.selected"
          :disabled="item.disabled"
        >
          {{ item.label }}
        </fr-select-option>
      </template>      

      <!-- If the object has no `value` key, and has `items` and a `label`, it is an optgroup. -->
      <template v-if="Object.prototype.hasOwnProperty.call(option, 'items') && option.label !== null">
        <fr-select-opt-group
          v-if="option.items.length > 0"
          :key="optionIndex"
          :label="option.label"
        >
          <fr-select-option
            v-for="(item, itemIndex) in option.items"
            :key="itemIndex"
            :value="item.value"
            :selected="item.selected"
            :disabled="item.disabled"
          >
            {{ item.label }}
          </fr-select-option>
        </fr-select-opt-group>
      </template>
    </template>
  </select>
  <fr-field-error 
    v-if="schemaHasErrors()"
  >
    {{ errors() }}
  </fr-field-error>
</template>

<script>
import { mapGetters } from "vuex";
import validationHelper from '@/helpers/validation'
import FrSelectOption from '@/components/fields/base/FrSelectOption.vue'
import FrSelectOptGroup from '@/components/fields/base/FrSelectOptGroup.vue'
import { nextTick } from "vue";

export default {
  name: 'FrSelect',

  components: {
    FrSelectOption,
    FrSelectOptGroup
  },

  props: {
    /**
     * The ID of the select element.
     */
    id: {
      type: String,
      required: true
    },

    /**
     * The CSS classes to be applied to the element.
     */
     class: {
      type: String,
      required: false,
      default: ''
    },    

    /** 
     * The name of the select element.
     */
    name: {
      type: String,
      required: false,
      default: ''
    },

    /**
     * The default option to render for the select element.
     * 
     * This will always be displayed at the top of the list.
     */
    defaultOption: {
      type: Object,
      required: false,
      default: null
    },

    /**
     * The options to render for the select element.
     */
    options: {
      type: Array,
      required: true
    },

    /**
     * The value that is passed from the parent component through `v-model`.
     */
    modelValue: {
      type: [Array, Object, String, Number, Boolean],
      default: undefined
    },
    
    valid: {
      type: Boolean,
      required: false,
      default: true
    },

    required: {
      type: Boolean,
      required: false,
      default: true
    },
    
    validateOnBlur: {
      type: Boolean,
      required: false,
      default: true
    },

    validateOnChange: {
      type: Boolean,
      required: false,
      default: true
    },

  },

  emits: [
    'update:modelValue',
    'blur',
    'change'
  ],

  data() {
    return {
      interacted: false,
    };
  },

  computed: {
    ...mapGetters({
      currentFormStep: "getCurrentFormStep",
      schemaObject: "getSchemaObject",
    }),
    /** 
     * The CSS classes to be applied to the element.
     * 
     * This is defined as a computed property so we can dynamically set classes.
     * 
     * @returns {Array}
     */
    cssClasses() {
      return { 'fr-select': true };
    },

    isInvalid() {
      return this.interacted && this.schemaHasErrors();
    },

    /**
     * The value that is passed from the parent component through `v-model`.
     * 
     * This is wrapped as a computed property so that it may be bound 
     * as a `v-model` to a child component. Setting this up as a proxy 
     * bypasses the `Avoid mutating a prop directly` error thrown by Vue.
     * Instead, we intercept this mutation and pass it along to the parent.
     * 
     * @param {String} val
     * 
     * @returns {String}
     */
    fieldValue: {
      get() { return this.modelValue },
      set(v) { 
        this.$emit('update:modelValue', v) 
      }
    },
  },

  methods: {
    onBlur() {
      this.interacted = true;
      if (this.validateOnBlur) {
        this.schemaObject.errors[this.schemaObject.lut[this.name]] = []
        this.validate()
      }
      this.$emit('blur')
    },

    onChange() {
      this.interacted = true;
      if (this.validateOnChange) {
        this.schemaObject.errors[this.schemaObject.lut[this.name]] = []
        this.validate()
      }
      this.$emit('change')
    },

    errors() {
      return this.schemaObject.errors[this.schemaObject.lut[this.name]][0]
    },

    schemaHasErrors() {
      return !!this.schemaObject.errors[this.schemaObject.lut[this.name]]
        && this.schemaObject.errors[this.schemaObject.lut[this.name]].length > 0
    },

    async validate() {
      await nextTick() // otherwise this.modelValue will lag behind
      //check if that field exists in the schema first)
  
      let validationValue = this.name === 'fr-programs-filter' ? this.modelValue.value : this.modelValue

      if(this.schemaObject.lut[this.name] in this.schemaObject.schema[this.currentFormStep - 1].fields) {
        this.schemaObject.schema[this.currentFormStep - 1].fields[this.schemaObject.lut[this.name]].validate(validationValue).then(result => {
        this.schemaObject.errors[this.schemaObject.lut[this.name]] = []
        }).catch(err => {
          this.schemaObject.errors[this.schemaObject.lut[this.name]] = [err.message]
        })
      }
      
    },

    /** 
     * The CSS classes to be applied for validation purposes.
     * 
     * @returns {Object}
     */
    validationClasses(name) {
      return validationHelper.createValidationClasses(name)
    },

    focus() {
      this.$refs.selectField.focus();
    }
  }
}
</script>

<style lang="scss" scoped>
.fr-select {
  padding: 5px;
  margin-bottom: 5px;
  width: 100%;

  transition: box-shadow ease 0.25s;
  border: 1px solid rgba(0, 0, 0, 0.5);
  
  &:focus {
    outline: none;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  }
}
</style>