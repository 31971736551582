export default [
  { 
    label: "Associate's Degree",
    value: "Associate's Degree"
  },
  { 
    label: "Bachelor's Degree",
    value: "Bachelor's Degree"
  },
  { 
    label: 'Currently Enrolled in High School',
    value: 'Currently Enrolled in High School'
  },
  { 
    label: 'Doctoral Degree',
    value: 'Doctoral Degree'
  },
  { 
    label: 'GED',
    value: 'GED'
  },
  { 
    label: 'High School Diploma or GED',
    value: 'High School Diploma or GED'
  },
  { 
    label: "Master's Degree",
    value: "Master's Degree"
  },
  { 
    label: 'Post Doctoral',
    value: 'Post Doctoral'
  },
  { 
    label: 'Some College',
    value: 'Some College'
  }
]
