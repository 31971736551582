<template>
  <button 
    v-bind="$attrs"
    :class="cssClasses"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'FrButton',

  props: {
    /**
     * The classes to append to the element.
     */
    classes: {
      type: Array,
      required: false,
      default: () => [] // This must return a function.
    }
  },

  computed: {
    /** 
     * The CSS classes to be applied to the element.
     * 
     * This is defined as a computed property so we can dynamically set classes.
     * 
     * @returns {Object}
     */
    cssClasses() {
      return [
        'fr-button',
        ...this.classes
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.fr-button {
  /* Reset the default button styles to prevent any overrides.
   * This may be able to be extracted to a different place.
   * I've left it here though so I can see everything from
   * a component level and not have to hunt for the code.
  ** ------------------------------------------------------ */
  margin: 0;
  padding: 0;
  background: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  text-decoration: none;
  /* ------------------------------------------------------ */

  flex-grow: 1;
  margin: 0 10px; // This gives margin between the buttons
  padding: 5px 10px;
  min-width: 50px;

  &:hover {
    cursor: pointer;
  }

  &:first-child {
    margin-left: 0; // Remove margin on left if the button is the first one.
  }

  &:last-child {
    margin-right: 0; // Remove margin on right if the button is the last one.
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  }

  // Default styles intended to be overridden.
  background: #333333;
  background-color: #333333;
  color: rgba(255, 255, 255, 0.87);
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
}
</style>