<template>
  <input 
    :id="id" 
    v-model="fieldValue" 
    ref="inputField" 
    :class="{ ...cssClasses, ...validationClasses(name) }"
    :name="name" 
    :type="type" 
    :placeholder="placeholder" 
    :pattern="pattern" 
    :autocomplete="autocomplete"
    :disabled="disabled" 
    :aria-invalid="isInvalid" 
    :aria-required="required"
    :aria-describedby="schemaHasErrors() ? `${id}-error` : null" 
    @blur="onBlur" 
    @change="onChange"
  >
  <fr-field-error v-if="!isTrue(squashErrors) && schemaHasErrors()">
    {{ errors() }}
  </fr-field-error>
</template>

<script>
import { mapGetters } from "vuex";
import validationHelper from '@/helpers/validation'
import { nextTick } from "vue";

export default {
  name: "FrInput",

  props: {
    /**
     * The ID of the input element.
     */
    id: {
      type: String,
      required: true,
    },

    /**
     * The CSS classes to be applied to the element.
     */
     class: {
      type: String,
      required: false,
      default: ''
    },

    /**
     * The name of the input element.
     */
    name: {
      type: String,
      required: false,
      default: ''
    },

    /**
     * The type of the input element.
     */
    type: {
      type: String,
      required: false,
      default: ''
    },

    /**
     * The placeholder for the input element.
     */
    placeholder: {
      type: [String, Number],
      required: false,
      default: ''
    },

    /**
     * The pattern for the input element.
     */
    pattern: {
      type: String,
      required: false,
      default: ''
    },

    /**
     * The autocomplete property to be passed to the input element.
     */
    autocomplete: {
      type: [String, Number, Boolean],
      required: false,
      default: null
    },

    /**
     * The disabled property to be passed to the input element.
     */
    disabled: {
      type: [String, Number, Boolean],
      required: false,
      default: false
    },

    /**
     * The value that is passed from the parent component through `v-model`.
     */
    modelValue: {
      type: [Array, Object, String, Number, Boolean],
      default: undefined
    },

    valid: {
      type: Boolean,
      required: false,
      default: true
    },

    required: {
      type: Boolean,
      required: false,
      default: true
    },

    squashErrors: {
      type: Boolean,
      required: false,
      default: false
    },

    validateOnBlur: {
      type: Boolean,
      required: false,
      default: true
    },

    validateOnChange: {
      type: Boolean,
      required: false,
      default: true
    },

  },

  emits: [
    'handleErrors',
    'update:modelValue',
    'blur',
    'change'
  ],

  data() {
    return {
      interacted: false,
    };
  },

  computed: {
    ...mapGetters({
      currentFormStep: "getCurrentFormStep",
      schemaObject: "getSchemaObject",
    }),

    watchedError() {
      return this.schemaObject?.errors?.[this.schemaObject?.lut?.[this.name]]
    },

    /**
     * The CSS classes to be applied to the element.
     *
     * This is defined as a computed property so we can dynamically set classes.
     *
     * @returns {Array}
     */
    cssClasses() {
      return { 'fr-input': true };
    },

    isInvalid() {
      return this.interacted && this.schemaHasErrors();
    },

    /**
     * The value that is passed from the parent component through `v-model`.
     *
     * This is wrapped as a computed property so that it may be bound
     * as a `v-model` to a child component. Setting this up as a proxy
     * bypasses the `Avoid mutating a prop directly` error thrown by Vue.
     * Instead, we intercept this mutation and pass it along to the parent.
     *
     * @param {String} val
     *
     * @returns {String}
     */
    fieldValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },

  watch: {
    watchedError: {
      handler(newVal) {
        this.$emit('handleErrors', newVal);
      },
      deep: true,
      immediate: false
    }
  },

  methods: {
    isTrue(value) {
      return value === true || (typeof value === "string" && value.toLowerCase() === "true");
    },

    onBlur(){
      this.interacted = true;

      if(this.isTrue(this.validateOnBlur)){
        this.schemaObject.errors[this.schemaObject.lut[this.name]] = []
        this.validate()
      }

      this.$emit('blur')
    },

    onChange() {
      this.interacted = true;
      
      if (this.isTrue(this.validateOnChange)) {
        this.schemaObject.errors[this.schemaObject.lut[this.name]] = []
        this.validate()
      }

      this.$emit('change')
    },

    errors() {
      return this.schemaObject.errors[this.schemaObject.lut[this.name]][0]
    },

    schemaHasErrors() {
      return !!this.schemaObject.errors[this.schemaObject.lut[this.name]]
        && this.schemaObject.errors[this.schemaObject.lut[this.name]].length > 0
    },

    async validate() {
      await nextTick() // otherwise this.modelValue will lag behind
      //check if that field exists in the schema first
      if(this.schemaObject.lut[this.name] in this.schemaObject.schema[this.currentFormStep - 1].fields) {
        this.schemaObject.schema[this.currentFormStep - 1].fields[this.schemaObject.lut[this.name]].validate(this.modelValue).then(result => {
        this.schemaObject.errors[this.schemaObject.lut[this.name]] = []
        }).catch(err => {
          this.schemaObject.errors[this.schemaObject.lut[this.name]] = [err.message]
        })
      }
    },

    /** 
     * The CSS classes to be applied for validation purposes.
     * 
     * @returns {Object}
     */
    validationClasses(name) {
      return validationHelper.createValidationClasses(name)
    },

    focus() {
      this.$refs.inputField.focus();
    },
  }
};
</script>

<style lang="scss" scoped>
.fr-input:not([type="checkbox"], [type="tel"]) {
  padding: 5px;
  margin-bottom: 5px;
  width: 100%;

  transition: box-shadow ease 0.25s;
  border: 1px solid rgba(0, 0, 0, 0.5);

  &:focus {
    outline: none;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  }
}

.fr-input[type="checkbox"] {
  margin-right: 10px;
}

.fr-input[type="tel"] {
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  width: 100%;

  transition: box-shadow ease 0.25s;
  border: 1px solid rgba(0, 0, 0, 0.5);

  &:focus {
    outline: none;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  }
}
</style>
